
import Vue, { PropType } from 'vue';
import BillLayout from '@/layouts/BillLayout.vue';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import { RefundsInfo, REFUNDS_DEFAULT } from '@/api/infra-renew/types';

export default Vue.extend({
  name: 'NimsEmsStep02Template',
  components: { BillLayout, FormLayout, TableLayout, ColumnLayout },
  props: {
    refundsInfo: {
      type: Object as PropType<RefundsInfo>,
      default: REFUNDS_DEFAULT,
    },
  },
  data() {
    return {
      formTitle,
      myGabiaUrl: `${process.env.VUE_APP_MY_GABIA_URL}/dashboard#/`,
    };
  },
  computed: {},
  watch: {},
  methods: {},
});
