import { instance } from '../instance';
import { AxiosPromise } from 'axios';
import {
  NimsGroup,
  NimsManager,
  NimsGoodsInfo,
  ManagerModifyParam,
  NimsEquipment,
  TechHelpResponse,
  TechHelpInfo,
  ChangeOrderResponse,
  ChageOrderCompleteResponse,
  RefundsInfo,
} from './types';
const BASEURL = process.env.VUE_APP_INFRA_API;

const infraAPI = instance.createWithoutInterceptors(BASEURL, {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

export function getGroupList(): AxiosPromise<{ data: NimsGroup[] }> {
  return infraAPI.get(`/contracts?delete_flag=N`);
}

export function getGroupInfo(groupSeqNo: number): AxiosPromise<{ data: NimsGroup }> {
  return infraAPI.get(`/contracts/${groupSeqNo}?has_equipments=Y`);
}

export function getTechHelpList(
  groupSeqNo: string | number,
  equipmentSeqNo: string | number,
): AxiosPromise<{ data: TechHelpInfo[] }> {
  return infraAPI.get(`/contracts/${groupSeqNo}/goods/${equipmentSeqNo}/tech-support`);
}

export function getGroupManagerList(groupSeqNo: number): AxiosPromise<{ data: NimsManager[] }> {
  return infraAPI.get(`/contracts/${groupSeqNo}/managers?delete_flag=N`);
}

export function getGroupEquipments(groupSeqNo: number): AxiosPromise<{ data: NimsEquipment[] }> {
  return infraAPI.get(`/contracts/${groupSeqNo}/service-goods?mid_class_code=EQUIPMENT&status[]=9`);
}

export function getGroupServiceValidate(
  groupSeqNo: number,
  type: 'S' | 'E', // S 신규, E 연장
): AxiosPromise<{ data: { result: 'Y' | 'N'; message: string } }> {
  return infraAPI.get(`/contracts/${groupSeqNo}/validate?type=${type}`);
}

export function getHasOldIdc(userId: string): AxiosPromise<{ user_id: string; has_old_idc_goods: boolean }> {
  return infraAPI.get(`/has-old-idc/${userId}`);
}

export function getGroupEquipmentInfo(
  groupSeqNo: string | number,
  goodsSeqNo: string | number,
): AxiosPromise<{ data: NimsEquipment }> {
  return infraAPI.get(`/contracts/${groupSeqNo}/goods/${goodsSeqNo}`);
}

export function getOneMinuteServerHasStock(
  goods_id: number,
  os: 'L' | 'W',
): AxiosPromise<{ data: { stock: 'Y' | 'N' } }> {
  return infraAPI.get(`/onesrv-stock?goods_id=${goods_id}&os=${os}`);
}

export function putGroupInfo(groupSeqNo: number, params): AxiosPromise<{ data: any }> {
  return infraAPI.put(`/contracts/${groupSeqNo}`, params);
}

export function putTechHelpInfo(techSeqNo: number, params): AxiosPromise<{ data: any }> {
  return infraAPI.put(`/tech-support/${techSeqNo}`, params);
}

export function putNimsSettingInfo(groupSeqNo: number, seqQuery: string, params): AxiosPromise<{ data: any }> {
  return infraAPI.put(`/contracts/${groupSeqNo}/goods?${seqQuery}`, params);
}

export function putManagerInfo(
  groupSeqNo: number,
  managerSeqNo: number,
  params: ManagerModifyParam,
): AxiosPromise<any> {
  return infraAPI.put(`/contracts/${groupSeqNo}/managers/${managerSeqNo}`, params);
}

export function createGroupForInfra(settle_id: string): AxiosPromise<{ data: any }> {
  const params = new URLSearchParams();
  params.append('seqno', settle_id);

  return infraAPI.post(`/contracts`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function createRefundsForInfra(refundsInfo): AxiosPromise<{ data: any }> {
  const params = new FormData();
  params.append('account_bank', refundsInfo.bankName);
  params.append('account_number', refundsInfo.bankAccountNo);
  params.append('account_holder', refundsInfo.userName);
  params.append('target_seqno', refundsInfo.targetSeqNo);

  if (refundsInfo.attchFile) {
    params.append('attached_file', refundsInfo.attchFile);
  }

  return infraAPI.post(`/change-refunds`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function createGoodsInfoInfra(
  groupSeqNo: number,
  orderSeqNo: number,
  osChecker: 'L' | 'W' | 'N',
): AxiosPromise<{ data: NimsGoodsInfo[] }> {
  return infraAPI.post(`/contracts/${groupSeqNo}/payments/${orderSeqNo}?os=${osChecker}`);
}

export function createModifyGoodsForInfra(goodsSeqNo: string, params): AxiosPromise<{ data: ChangeOrderResponse }> {
  return infraAPI.post(`/goods/${goodsSeqNo}/change-orders`, params);
}

export function getModifyGoodsInfo(
  goodsSeqNo: string | number,
  paymentSeqNo: string | number,
): AxiosPromise<{ data: ChageOrderCompleteResponse }> {
  return infraAPI.get(`/goods/${goodsSeqNo}/change-orders?payment_seqno=${paymentSeqNo}`);
}

export function getRefundsInfo(paymentSeqNo: string): AxiosPromise<{ data: RefundsInfo[] }> {
  return infraAPI.get(`/change-refunds?payment_seqno=${paymentSeqNo}`);
}

export function createTechHelpInfra(
  groupSeqNo: string,
  suportGoodsSeqNo: string,
  paymentSeqNo: string,
): AxiosPromise<{ data: TechHelpResponse }> {
  return infraAPI.post(
    `/contracts/${groupSeqNo}/goods/${suportGoodsSeqNo}/tech-support?payment_seqno=${paymentSeqNo}`,
    {
      'contract-seqno': groupSeqNo,
      'goods-seqno': suportGoodsSeqNo,
    },
  );
}
