export interface NimsGroup {
  seqno: string;
  group_name: string;
  group_expire_date: string;
  group_pre_expire_date: string;
  charge_date: string;
  taxbill_flag: 'Y' | 'N';
  taxbill_date: string;
  taxbill_auto: 'Y' | 'N';
  demand_period: string;
  regist_date: string;
  charge_period: string;
  loan_day: string;
  has_equipments: 'Y' | 'N';
  taxinfo_seqno: string;
  extendable: 'Y' | 'N';
  delete_flag: 'Y' | 'N';
  delete_date: null | string;
  user_id: string;
  extend_day: {
    code: string;
    label: string;
  };
  service: {
    alias: string;
    createdate: string;
    expire_date: string;
    paid_start_date: string;
    pay_method: null | string;
    pay_type: {
      code: string;
      label: string;
    };
    pre_expire_date: string;
  };
}

export interface NimsEquipment {
  seqno: string;
  alias: string;
  goods_id: number;
  goods_name: string;
  idc: {
    code: string;
    label: string;
  };
  server_ip: null | string;
  main_goods_view: {
    mid_class_code: {
      id: string;
      code: string;
      label: string;
    };
    sub_class_code: {
      id: string;
      code: string;
      label: string;
    };
    status: {
      code: string;
      label: string;
    };
    apply_date: string;
  };
}

export interface NimsManager {
  contract_group_seqno: string;
  manager_email: string;
  manager_kind: {
    code: 'S' | 'B'; // S,B  서비스, 요금
    label: string;
  };

  manager_mobile: string;
  manager_name: string;
  manager_phone: string;
  represent_flag: 'Y' | 'N'; // 대표관리자
  seqno: number;
}

export interface NimsGoodsInfo {
  seqno: number;
  contract_group_seqno: number;
  classification_id: number;
  parents_seqno: number;
  goods_id: number;
  goods_name: string;
  alias: string;
  idc: string;
  os: string;
  term_unit: string;
}

export interface ManagerModifyParam {
  manager_name: string;
  manager_email: string;
  manager_phone: string;
  manager_mobile: string;
}

export interface TechHelpResponse {
  example: string;
  summary: string;
  value: {
    data: TechHelpInfo[];
  };
}

export interface TechHelpInfo {
  status: {
    code: number;
    label: string;
  };
  user_id: string;
  goods_id: string;
  goods_name: string;
  service_goods_main_seqno: string;
  regist_date: string;
  seqno: number;
}

export interface TechHelpRequestForm {
  expose_name: string;
  goods_id: string;
  infra_seqno: number;
  goods_type: {
    code: string;
    name: string;
  };
  request_text: string;
}

export interface ChangeOrderResponse {
  change_code: {
    code: string;
    label: string;
  };
  before_value: string;
  after_value: string;
  shistory_rowid: number;
  target_seqno: string;
  contract_group_seqno: string;
  update_date: string;
  regist_date: string;
  seqno: number;
}

export interface ChageOrderCompleteResponse {
  after: { goods_id: string; goods_name: string; expose_name: string };
  before: { goods_id: string; goods_name: string; expose_name: string };
  contract_group_seqno: string;
  group_name: string;
  ordernum: string;
  request_date: string;
}

export interface RefundsInfo {
  seqno: number;
  before_value: string;
  after_value: string;
  change_code: string;
  shistory_rowid: string;
  status: {
    code: '0' | '1' | '8' | '9';
    label: string;
  };
  target_seqno: string;
  regist_date: string;
  update_date: string;
  contract_group_seqno: string;
  request_date: string | null;
  refund: null | ServiceGoodsRefundInfo;
}

export interface ServiceGoodsRefundInfo {
  seqno: number;
  type: string;
  account_bank: string;
  account_number: string;
  account_holder: string;
  attached_file_id: string;
  attached_file_size: string;
  update_date: string;
  target_seqno: string;
}

export const REFUNDS_DEFAULT: RefundsInfo = {
  seqno: 0,
  before_value: '',
  after_value: '',
  change_code: '',
  shistory_rowid: '',
  status: {
    code: '0',
    label: '',
  },
  target_seqno: '',
  regist_date: '',
  update_date: '',
  contract_group_seqno: '',
  request_date: null,
  refund: {
    seqno: 0,
    type: '',
    account_bank: '',
    account_number: '',
    account_holder: '',
    attached_file_id: '',
    attached_file_size: '',
    update_date: '',
    target_seqno: '',
  },
};
