
import Vue from 'vue';
import NimsEmsStep02Template from '@/templates/nims/NimsEmsStep02Template.vue';
import { getRefundsInfo } from '@/api/infra-renew/index';
import { ERROR_CODE_OBJ } from '@/assets/data/nims/constants';
import { RefundsInfo, REFUNDS_DEFAULT } from '@/api/infra-renew/types';

export default Vue.extend({
  name: 'NimsEmsStep02Brain',
  components: { NimsEmsStep02Template },
  props: {},
  data() {
    return {
      isRender: false,
      paymentSeqNo: '',
      refundsInfo: REFUNDS_DEFAULT as RefundsInfo,
    };
  },
  watch: {},
  async mounted() {
    this.paymentSeqNo = this.$route.query?.payment_seqno ? String(this.$route.query?.payment_seqno) : '';
    await this.getRefundsInfoFnc();
    this.$GlobalLoading.hide();
  },
  methods: {
    async getRefundsInfoFnc() {
      try {
        if (!this.paymentSeqNo) {
          throw new Error(ERROR_CODE_OBJ.WRONG_PARAMS);
        }

        const { data } = await getRefundsInfo(this.paymentSeqNo);

        if (!data.data.length || data.data[0].refund === null) {
          throw new Error(ERROR_CODE_OBJ.NO_MATCHING_INFO);
        } else {
          this.refundsInfo = data.data[0];
          this.isRender = true;
        }
      } catch (error) {
        const errorObj = error as Error;
        const errorMessage = errorObj.message || '불특정 에러가 발생했습니다.';
        alert(errorMessage);
        return;
      }
    },
  },
});
